import React from 'react'

// Components
import Icon from 'src/components/UI/MarkdownIcon'
import ImageWebp from 'src/components/ImageWebp'

// Style
import { Section, Blog, Tag } from './style'

// Assets
import AllAboutAccountsJSON from '../../assets/data/AllAboutAccounts.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type AllAboutAccountsProps = {
  image: string;
  tag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
  textColor: string;
  bgColor: string;
}

const AllAboutAccounts = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='d-flex py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-3 mb-md-2 mb-lg-3'>
            <h3 className='fs-24 fs-md-28 fs-xl-48 lh-29 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 text-md-center'>Tudo sobre contas para menor do Inter</h3>
          </div>
        </div>
        <div className='row'>
          {AllAboutAccountsJSON.map((item: AllAboutAccountsProps, index: number) => (
            <div key={index} className='col-12 col-md-4 mb-4'>
              <a
                data-blog={item.title}
                href={item.link}
                target='_blank'
                rel='noreferrer'
                title={`Leia mais em ${item.link}`}
                className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    section_name: 'Tudo sobre contas para menor do Inter',
                    element_action: 'click button',
                    element_name: `Leia mais - ${item.title}`,
                    redirect_url: item.link,
                  })
                }}
              >
                <Blog>
                  <ImageWebp
                    pathSrc={item.image}
                    altDescription={item.alt}
                    arrayNumbers={[ 312, 216, 296, 360 ]}
                    arrayNumbersHeight={[ 240, 150, 200, 244 ]}
                  />
                  <div className='pt-3 px-4 px-md-3 px-lg-3 mt-n5 bg'>
                    <Tag className='rounded-1 py-1' style={{ background: item.bgColor }}>
                      <p className={`${item.textColor} 'lh-15 fw-600 mb-0 px-2 mr-2'`}>{item.tag}</p>
                    </Tag>
                    <div className='text'>
                      <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                      <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                      <p className='fs-14 fs-lg-16 lh-19 lh-md-19 mb-2 fw-400 mt-3 text-grayscale--400'>{item.description}</p>
                    </div>
                    <span className='read-more text-orange--base'>Leia mais
                      <Icon width='22.5' height='24.34' color='orange--base' icon='navigation/arrow-right' directory='v2' />
                    </span>
                  </div>
                </Blog>
              </a>
            </div>
        ))}
        </div>
      </div>
    </Section>
  )
}

export default AllAboutAccounts
