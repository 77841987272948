import styled from 'styled-components'
import { sand } from 'src/styles/newColors'
import { white, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media (min-width: ${breakpoints.md}) {
    height: 560px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 540px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 726px;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;

   @media (min-width: ${breakpoints.md}) {
      left: 183px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

   @media (min-width: ${breakpoints.md}) {
      right: 203px;
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  background-color: ${sand};
  .title {
    border-radius: 20px;
    width: 190px;
  }
  .content {
    background-color: ${white};
    border-radius: 40px 20px 40px 20px;
    height: 110px;
  }

  a {
    text-transform: unset;
    min-width: 312px;
    position: relative;
    top: 15px;

    @media (min-width: ${breakpoints.md}) {
      min-width: 457px;
      top: 10px;
    }

    @media (min-width: ${breakpoints.lg}) {
      top: -20px;
    }

    @media (min-width: ${breakpoints.xl}) {
      top: 10px;
    }
  }
`

export const Icon = styled.div`
  position: relative;
  top: 12px;
  width: 38px;
`

export const Title = styled.div`
  position: relative;
  top: 12px;
`