import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-conta-menor-mobile/image.webp');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 719px;

  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-conta-menor-background/image.webp');
    height: 329px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 428px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 638px;
  }

  @media (min-width: ${breakpoints.xxl}){
    height: 710px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 840px;
  }

  a {
    text-transform: unset;
    @media (min-width: ${breakpoints.md}){
      max-width: 325px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 374px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }

    &:focus, &:hover {
      outline: none;
      text-decoration: none;
      color: ${orange.extra};
      border: none;
    }
  }
`
