import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import { Section } from './style'

const Hero = () => {
  return (
    <Section
      className='py-5 d-flex align-items-md-center'
      role='img'
      aria-label='Mmulher sorrindo segurando celular com fones de ouvido'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6 col-lg-5'>
            <h1 className='fs-28 fs-lg-40 fs-xl-56 lh-33 lh-lg-44 lh-xl-65 text-white fw-500'>
              Contas para menor de 18 anos: facilidade e segurança para toda família
            </h1>
            <p className='fs-16 lh-20 fw-400 text-white'>
              As contas digitais gratuitas para menores trazem praticidade e liberdade para a rotina das famílias, além de incentivar o aprendizado financeiro desde cedo.
            </p>
            <ScrollTo
              to='#kids-account'
              title='Saber mais'
              styles='btn btn--lg mb-md-4 btn--lg text-orange--extra bg-white rounded-2 fw-700'
              section='dobra_01'
              sectionName='Contas para menor de 18 anos: facilidade e segurança para toda família'
              elementName='Saber mais'
            >
              Saber mais
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
