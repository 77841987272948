import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      people: imageSharp(fluid: {originalName: { regex: "/people-icon/" }}) {
        fluid(maxWidth: 37, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      dolar: imageSharp(fluid: {originalName: { regex: "/dolar-icon/" }}) {
        fluid(maxWidth: 38, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heart: imageSharp(fluid: {originalName: { regex: "/heart-icon/" }}) {
        fluid(maxWidth: 37, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cel: imageSharp(fluid: {originalName: { regex: "/cel-icon/" }}) {
        fluid(maxWidth: 38, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
