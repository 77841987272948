import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media (min-width: ${breakpoints.md}) {
    height: 590px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 600px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 636px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 760px;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-track {
    @media (min-width: ${breakpoints.lg}) {
      padding: 30px 0 60px;
    }
  }

  a {
    text-transform: unset;
    min-width: 312px;
    position: relative;
    top: 15px;
    
    @media (min-width: ${breakpoints.md}) {
      min-width: 457px;
      top: -10px;
    }

    @media (min-width: ${breakpoints.lg}) {
      top: -20px;
    }

    @media (min-width: ${breakpoints.xl}) {
      top: -10px;
    }
  }
`

export const Content = styled.div`
  height: 175px;

  @media (min-width: ${breakpoints.lg}) {
    height: 180px;
  }
`
