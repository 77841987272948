import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`

  .react-multiple-carousel__arrow--left {
    left: -7px;
    
    @media (min-width: ${breakpoints.md}){
      left: 140px;
    }
    @media (min-width: ${breakpoints.lg}){
      left: 210px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media (min-width: ${breakpoints.md}){
      right: 140px;
    }
    @media (min-width: ${breakpoints.lg}){
      right: 210px;
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  @media (min-width: ${breakpoints.md}){
    height: 590px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 600px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 600px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 640px;
  }
`

export const Carousel = styled.div`
  position: relative;
  right: -12px;

  @media (min-width: ${breakpoints.md}){
    right: 0;
    height: 250px;
  }
`
