import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section, Content } from './style'
import FinancialIndependenceJSON from '../../assets/data/FinancialIndependence.json'

type FinancialIndependenceProps = {
  title: string;
  description: string;
}

const FinancialIndependence = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-md-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 d-flex justify-content-center justify-content-lg-start'>
            <ImageWebp
              arrayNumbers={[ 288, 334, 452, 516 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-1440-conta-menor/image.webp'
              altDescription='Mulher sorrindo com celular na mão e fones de ouvido'
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 pr-md-0'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-29 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>
              Independência financeira para jovens e praticidade para pais: Inter You
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-md-22 fw-400 text-grayscale--500 mt-3 mb-0'>
              A Inter You é a conta ideal para os jovens de 13 a 17 anos que buscam a liberdade de gerenciar o próprio dinheiro, e para os pais que desejam liberar a mesada sem se preocupar com taxas e complicações.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 140 }}
              md={{ items: 4 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
            >
              { FinancialIndependenceJSON.map((item: FinancialIndependenceProps) => (
                <Content key={item.title} className='bg-gray rounded-4 mr-3 px-3 py-3'>
                  <p className='fs-16 fs-lg-20 fs-xl-24 lh-20 lh-lg-25 lh-xl-30 text-grayscale--500 fw-500'>{item.title}</p>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--400 mt-3'>{item.description}</p>
                </Content>
                )
              )}
            </DefaultCarousel>
          </div>
          <div className='w-100 d-flex justify-content-center'>
            <Link
              title='Conheça agora'
              to='/pra-voce/conta-digital/inter-you/'
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-2 mt-md-0'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_03',
                element_action: 'click button',
                element_name: 'Conheça agora',
                section_name: 'Independência financeira para jovens e praticidade para pais: Inter You',
              })}
            >
              Conheça agora
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FinancialIndependence
